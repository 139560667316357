<template>
  <div class="project-management mt-3">
    <ProgressBar v-if="projectLoading" mode="indeterminate" style="height: 1em" />
    <div v-else class="project-management-content">
      <div class="p-card p-3 mb-1">
        <Steps :model="steps" class="model-creation-steps" />
      </div>
      <router-view v-slot="{ Component }" @step="$router.push({ name: $event })">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import { computed, ref, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useMessage } from '@/dockone/app/message'

export default {
  name: 'ProjectManagement',
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const message = useMessage()

    const pathProjectId = computed(() => route.params.id)
    const storeProjectId = computed(() => store.getters['autocbm/project/projectId'])
    const projectLoading = ref(false)
    const steps = computed(() => [
      { label: t('autocbm.projects.management.steps.load'), to: `/project/${pathProjectId.value}/data/load` },
      { label: t('autocbm.projects.management.steps.selection'), to: `/project/${pathProjectId.value}/data/select` },
      // { label: t('autocbm.projects.management.steps.preparation'), to: `/project/${pathProjectId.value}/data/prepare` },
      { label: t('autocbm.projects.management.steps.training'), to: `/project/${pathProjectId.value}/training` },
      { label: t('autocbm.projects.management.steps.evaluation'), to: `/project/${pathProjectId.value}/evaluation` }
    ])

    const actions = {
      loadProject () {
        projectLoading.value = true
        return store.dispatch('autocbm/project/load', pathProjectId.value)
            .catch(error => {
              if (error && error.response && error.response.status !== 401 && error.response.status !== 400) {
                message.error(t('autocbm.projects.action.load.error.summary'), t('autocbm.projects.action.load.error.detail', { id: pathProjectId.value }))
                router.push({ name: 'project_not_found' })
              }
            })
            .finally(() => projectLoading.value = false)
      },
      clearProject () {
        return store.dispatch('autocbm/project/clear')
      },
      clearMl () {
        return store.dispatch('autocbm/projectMl/clear')
      }
    }

    if (storeProjectId.value !== pathProjectId.value) {
      actions.loadProject()
    }

    onBeforeUnmount(() => {
      actions.clearProject()
      actions.clearMl()
    })

    return { t, steps, projectLoading }
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 900px) {
  .model-creation-steps.p-steps {
    .p-steps-item {
      .p-menuitem-link {
        flex-direction: column;

        .p-steps-title {
          margin-top: .5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .model-creation-steps.p-steps {
    .p-steps-item {
      .p-menuitem-link {
        flex-direction: row;

        .p-steps-title {
          display: none;
        }
      }
    }
  }
}
</style>
